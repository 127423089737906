/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-11-04 15:20:56
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-11-04 18:06:54
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getInfluencerPlatformModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  result.parentName = language("influencerAccount.influencerPlatform");
  if (route.path.indexOf("influencer-accounts") > -1) {
    result.currentName = language("common.distributionInfluencerAccount");
  } else if (route.path.indexOf("social-connections") > -1) {
    result.currentName = language("influencerAccount.socialConnection");
  } else if (route.path.indexOf("capability-models") > -1) {
    result.currentName = language("capabilityModel.capabilityModels");
  } else if (route.path.indexOf("growth-models") > -1) {
    result.currentName = language("growthModels.growthModels");
  } else if (route.path.indexOf("influencer-groups") > -1) {
    result.currentName = language("influencerGroups.influencerGroups");
  }
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setInfluencerPlatformDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter
): void => {
  result.parentName = language("influencerAccount.influencerPlatform");
  if (route.path.indexOf("/influencer-account/") > -1) {
    result.listName = language("common.distributionInfluencerAccount");
    result.listPath = "/influencer-platform/influencer-accounts";
  } else if (route.path.indexOf("/social-connections/") > -1) {
    result.listName = language("influencerAccount.socialConnection");
    result.listPath = "/influencer-platform/social-connections";
  } else if (route.path.indexOf("capability-model") > -1) {
    result.listName = language("capabilityModel.capabilityModels");
    result.listPath = "/influencer-platform/capability-models";
  } else if (route.path.indexOf("growth-model") > -1) {
    result.listName = language("growthModels.growthModels");
    result.listPath = "/influencer-platform/growth-models";
  } else if (route.path.indexOf("influencer-groups") > -1) {
    result.listName = language("influencerGroups.influencerGroups");
    result.listPath = "/influencer-platform/influencer-groups";
  }
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getInfluencerPlatformDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "influencer-account-overview":
      result.currentName = language("common.overview");
      setInfluencerPlatformDetailName(language, route, result);
      break;
    case "account-information":
    case "social-connections-information":
    case "capability-model-information":
    case "growth-model-information":
    case "influencer-groups-information":
      result.currentName = language("common.information");
      setInfluencerPlatformDetailName(language, route, result);
      break;
    case "account-profile":
      result.currentName = language("influencerAccount.profile");
      setInfluencerPlatformDetailName(language, route, result);
      break;
    case "account-interactions":
      result.currentName = language("influencerAccount.interactions");
      setInfluencerPlatformDetailName(language, route, result);
      break;
    case "account-capability-growth":
      result.currentName = language("influencerAccount.capabilityAndGrowth");
      setInfluencerPlatformDetailName(language, route, result);
      break;
  }
  return result;
};
