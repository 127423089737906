/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-11-04 15:20:56
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-11-04 18:51:28
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getTaskManagementModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setTaskManagementDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setTaskManagementDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/task-management/";
  result.parentName = language("accountValidation.taskManagement");
  if (route.path.indexOf("account-validation") > -1) {
    strName = language("accountValidation.accountValidation");
    listPath += "account-validation";
  } else if (route.path.indexOf("call-out-records") > -1) {
    strName = language("callOutRecords.CallOutRecords");
    listPath += "call-out-records";
  } else if (route.path.indexOf("quick-inquiry") > -1) {
    strName = language("influencerAccount.quickInquiry");
    listPath += "quick-inquiry";
  } else if (route.path.indexOf("standard-operating-procedure") > -1) {
    strName = language("standardOperatingProcedure.standardOperatingProcedure");
    listPath += "standard-operating-procedure";
  } else if (route.path.indexOf("standard-script") > -1) {
    strName = language("standardScript.standardScript");
    listPath += "standard-script";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getTaskManagementDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "account-validation-information":
    case "call-out-records-information":
    case "quick-inquiry-information":
    case "standard-operating-procedure-information":
    case "standard-script-information":
      result.currentName = language("common.information");
      setTaskManagementDetailName(language, route, result, router);
      break;
  }
  return result;
};
