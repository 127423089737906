/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-11-04 15:20:56
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-11-04 16:55:47
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getDistributionNetworkModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  result.parentName = language("common.distributionNetwork");
  if (route.path.indexOf("distribution-channels") > -1) {
    result.currentName = language("common.distributionChannels");
  } else if (route.path.indexOf("warning-rules") > -1) {
    result.currentName = language("common.warningRules");
  }
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setDistributionNetworkDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter
): void => {
  result.parentName = language("common.distributionNetwork");
  if (route.path.indexOf("/distribution-network/") > -1) {
    result.listName = language("common.distributionChannels");
    result.listPath = "/distribution-network/distribution-channels";
  } else if (route.path.indexOf("warning-rules") > -1) {
    result.listName = language("common.warningRules");
    result.listPath = "/distribution-network/warning-rules";
  }
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getDistributionNetworkDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "distribution-channels-information":
      result.currentName = language("common.information");
      setDistributionNetworkDetailName(language, route, result);
      break;
  }
  return result;
};
