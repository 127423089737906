/*
 * @Descripttion:
 * @Author: xiang.gao 1543865938
 * @Date: 2022-06-09 16:36:06
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-01-26 17:21:23
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { Modal } from "bootstrap";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { CommonDateType, CommonLanguageType } from "../type/common";
import { CommonModuleRouter } from "../interface/common";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
  getDistributionNetworkDetailModule,
  getDistributionNetworkModule,
} from "./distributionNetwork";
import {
  getInfluencerPlatformDetailModule,
  getInfluencerPlatformModule,
} from "./influencerPlatform";
import {
  getInteractiveMarketingDetailModule,
  getInteractiveMarketingModule,
} from "./interactiveMarketing";
import {
  getTaskManagementDetailModule,
  getTaskManagementModule,
} from "./taskManagement";

/**
 * @description: 获取列表
 * @return CommonModuleRouter
 */
const getModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  if (route.path.indexOf("/distribution-network/") > -1) {
    return getDistributionNetworkModule(language, route);
  } else if (route.path.indexOf("/influencer-platform/") > -1) {
    return getInfluencerPlatformModule(language, route);
  } else if (route.path.indexOf("/interactive-marketing/") > -1) {
    return getInteractiveMarketingModule(language, route);
  } else if (route.path.indexOf("/task-management/") > -1) {
    return getTaskManagementModule(language, route);
  }
  return getDistributionNetworkModule(language, route);
};

/**
 * @description: 获取详情
 * @return CommonModuleRouter
 */
const getDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  if (route.path.indexOf("/distribution-network/") > -1) {
    return getDistributionNetworkDetailModule(language, route, router);
  } else if (route.path.indexOf("/influencer-platform/") > -1) {
    return getInfluencerPlatformDetailModule(language, route, router);
  } else if (route.path.indexOf("/interactive-marketing/") > -1) {
    return getInteractiveMarketingDetailModule(language, route, router);
  } else if (route.path.indexOf("/task-management/") > -1) {
    return getTaskManagementDetailModule(language, route, router);
  }
  return getDistributionNetworkDetailModule(language, route, router);
};

/**
 * @description: 设置BCN
 * @return CommonModuleRouter
 */
export const setModuleBCN = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router?: Router
): void => {
  if (router) {
    const moudleResult = getDetailModule(language, route, router);
    setCurrentPageBreadcrumbs(moudleResult.currentName, [
      {
        name: moudleResult.parentName,
        path: "",
      },
      {
        name: moudleResult.listName,
        path: moudleResult.listPath,
      },
    ]);
  } else {
    const moudleResult = getModule(language, route);
    setCurrentPageBreadcrumbs(moudleResult.currentName, [
      { name: moudleResult.parentName, path: "" },
    ]);
  }
};

/**
 * @description: 返回上一页（没有上一页时返回指定页面）
 * @param {Router} router
 * @param {string} path
 * @return {*}
 */
export const commonBackToList = (router: Router, path: string): void => {
  if (window.history.state.back) {
    router.back();
  } else {
    router.replace(path);
  }
};

export const CommonArrayToString = (value: Array<number | string>) => {
  return value.toString().replaceAll(",", ", ");
};

export const commonDefaultDate = (date): string => {
  return moment(date).add(12, "hours").toISOString();
};

export const commonDefaultAddDate = (date, day: number): string => {
  return moment(date).add(12, "hours").add(day, "days").toISOString();
};

export const commonChangeDefaultDate = (
  e,
  form,
  data: string,
  type?: string
): void => {
  const value = moment(e, "YYYY-MM-DD").add(12, "hours").toISOString();
  if (type) {
    form[data][type] = value;
  } else {
    form[data] = value;
  }
};

export const commonChangeDefaultDateTime = (
  e,
  form,
  data: string,
  type?: string
): void => {
  const value = moment(e, "YYYY-MM-DD HH:mm:ss").add(12, "hours").toISOString();
  if (type) {
    form[data][type] = value;
  } else {
    form[data] = value;
  }
};

export const formatDate = (date: CommonDateType, format = "YYYY-MM-DD") => {
  // return date ? moment(date).utcOffset(2).format(format) : "";
  // const localLocale = momentTimezone.tz.zonesForCountry("DE"); // 获取德国地区时区名字
  return date ? momentTimezone(date).tz("Europe/Berlin").format(format) : "";
};

export const formatDateTime = (
  date: CommonDateType,
  format = "YYYY-MM-DD HH:mm:ss"
) => {
  // return date ? moment(date).utcOffset(2).format(format) : "";
  // const localLocale = momentTimezone.tz.zonesForCountry("DE"); // 获取德国地区时区名字
  return date ? momentTimezone(date).tz("Europe/Berlin").format(format) : "";
};

/**
 * @description: 检查手机号
 * @param {string} mobile
 * @return boolean
 */
export const testMobile = (mobile: string): boolean => {
  const mobileRegexp = /^1[23456789]\d{9}$/;
  return mobileRegexp.test(mobile);
};

/**
 * @description: 检查身份证号
 * @param {string} IDCard
 * @return boolean
 */
export const testIDCard = (IDCard: string): boolean => {
  const idCardRegexp =
    /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  return idCardRegexp.test(IDCard);
};

/**
 * @description: 只能输入数字和英文字母
 * @param {string} value
 * @return string
 */
export const filterSpecialSymbolsChineses = (value: string): string => {
  return value.replace(/[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g, "");
};

/**
 * @description: 过滤特殊符号
 * @param {string} value
 * @return string
 */
export const filterSpecialSymbols = (value: string): string => {
  return value.replace(/[^a-zA-Z0-9\u4E00-\u9FA5]/g, "");
};

/**
 * @description: 过滤中文
 * @param {string} value
 * @return string
 */
export const filterChineses = (value: string): string => {
  return value.replace(/[\u4e00-\u9fa5/\s+/]/g, "");
};

/**
 * @description:
 * @param {HTMLElement} modalEl
 * @param {*} callback
 * @return {*}
 */
export const modalKeyDownListener = (
  modalEl: HTMLElement | null,
  callback
): void => {
  if (!modalEl) {
    return;
  }
  modalEl.addEventListener("keydown", callback);
};

/**
 * @description:
 * @return {*}
 */
export const modalRemoveKeyDownListener = (
  modalEl: HTMLElement | null,
  callback
): void => {
  if (!modalEl) {
    return;
  }
  modalEl.removeEventListener("keydown", callback);
};

export const showModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }
  const myModal = new Modal(modalEl);
  myModal.show();
};
