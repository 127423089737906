/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-11-04 15:20:56
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-11-04 18:35:03
 */
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { CommonModuleRouter } from "../interface/common";
import { CommonLanguageType } from "../type/common";

/**
 * @description: 获取path
 * @return CommonModuleRouter
 */
export const getInteractiveMarketingModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  setInteractiveMarketingDetailName(language, route, result);
  return result;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
const setInteractiveMarketingDetailName = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  result: CommonModuleRouter,
  router?: Router
): void => {
  let strName = "",
    listPath = "/interactive-marketing/";
  result.parentName = language("influencerAccount.interactiveMarketing");
  if (route.path.indexOf("account-conversions") > -1) {
    strName = language("common.distributionAccountConversion");
    listPath += "account-conversions";
  } else if (route.path.indexOf("interactive-activities") > -1) {
    strName = language("interactiveActivities.interactiveActivities");
    listPath += "interactive-activities";
  } else if (
    route.path.indexOf("wechat-communities") > -1 ||
    route.path.indexOf("wechat-community") > -1
  ) {
    strName = language("wechat.wechatCommunities");
    listPath += "wechat-communities";
  } else if (route.path.indexOf("marketing-campaign") > -1) {
    strName = language("marketingCampaigns.marketingCampaigns");
    listPath += "marketing-campaigns";
  }
  if (router) {
    result.listName = strName;
  } else {
    result.currentName = strName;
  }
  result.listPath = listPath;
};

/**
 * @description: 获取详情path和name
 * @return CommonModuleRouter
 */
export const getInteractiveMarketingDetailModule = (
  language: CommonLanguageType,
  route: RouteLocationNormalizedLoaded,
  router: Router
): CommonModuleRouter => {
  const result = {
    currentName: "",
    parentName: "",
    parentPath: "",
    listName: "",
    listPath: "",
  };
  switch (router.currentRoute.value.name) {
    case "account-conversions-overview":
      result.currentName = language("common.overview");
      setInteractiveMarketingDetailName(language, route, result, router);
      break;
    case "account-conversions-information":
    case "interactive-activities-information":
    case "marketing-campaign-information":
    case "wechat-community-information":
      result.currentName = language("common.information");
      setInteractiveMarketingDetailName(language, route, result, router);
      break;
    case "account-conversions-profile":
      result.currentName = language("influencerAccount.profile");
      setInteractiveMarketingDetailName(language, route, result, router);
      break;
    case "account-conversions-interactions":
      result.currentName = language("influencerAccount.interactions");
      setInteractiveMarketingDetailName(language, route, result, router);
      break;
    case "account-conversions-capability-growth":
      result.currentName = language("influencerAccount.capabilityAndGrowth");
      setInteractiveMarketingDetailName(language, route, result, router);
      break;
    case "interactive-activities-nextsteps":
      result.currentName = language("common.nextsteps");
      setInteractiveMarketingDetailName(language, route, result, router);
      break;
  }
  return result;
};
